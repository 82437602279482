
import React from 'react'
import store from '../../'
import { Redirect } from 'react-router-dom'
import { Auth } from '../../services/Auth';
import RolesHelper from './RolesHelper';

//  properties need to be like sentences 
// for view cases
// I="view" a="${abilities.view}"
// for add cases
// I="add" on="${abilities.add}"
// for delete cases
// I="delete" on="${abilities.delete}"
// for edit cases
// I="edit" on="${abilities.edit}"

// Page urls and permissions sections available in them
const RolesPages = {
    'projectSettings': ['projectSettings'],
    'uiCustomization': ['uiCustomization', 'controlsManagement'],
    'controlsManagement': ['controlsManagement']
}

// UI menus that contain resoures view
const RolesSubPage = {
    'magic icon': [
        'uiCustomization.uiFont',
        'uiCustomization.uiColor',
        'uiCustomization.uiButtons',
        'uiCustomization.uiInputs',
        'uiCustomization.uiIcon',
        'uiCustomization.uiLogos',
        'uiCustomization.uiBackgroundImages'
    ],
    'customCodes': [
        'projectSettings.settingsCustomScripts',
        'projectSettings.settingsCustomStyles',
    ],
    'more': [
        'controlsManagement.controlsSupport',
        'controlsManagement.controlsAgeRestriction',
        'controlsManagement.controlsCopyright',
        'controlsManagement.controlsAboutCompany',
        'controlsManagement.controlsMobileApps',
        // 'controlsManagement.controlsLanguage',
        // 'controlsManagement.controlsPoweredbyDigitain'
    ],
    'general settings': [
        'projectSettings.settingsLicense',
        'projectSettings.settingsAffiliate',
        'projectSettings.settingsReferAFriend',
        'projectSettings.settingsAgeRestriction',
    ],
    'UI Settings': [
        'uiCustomization.uiFont',
        'uiCustomization.uiColor',
        'uiCustomization.uiButtons',
        'uiCustomization.uiInputs'
    ],
    'uiPages': [
        'uiCustomization.uiPagesContent',
        'uiCustomization.uiPagesList',
        'uiCustomization.uiPopupManagement'
    ],
    'configurations': [
        'uiCustomization.apiSettingsManagement',
        'uiCustomization.openGraphManagement'
    ], 
}

// for page links that need to be hide if no permission section view is availabe
const getPageAvailability = (rules, page) => {
    const x = intersect(rules, RolesPages[page]);
    return x.length > 0;
}

// for menus that need to be hide if no resourse view is availabe
const getSubPageAvailability = (rules, page) => {
    let returnValue = false;
    RolesSubPage[page].forEach(a => {
        const ability = a.split('.');
        if (rules[ability[0]] && rules[ability[0]][ability[1]] && rules[ability[0]][ability[1]].length) {
            returnValue = true;
        }
    });
    return returnValue;
}

const intersect = (a, b) => {
    var setB = new Set(b);
    return [...new Set(Object.keys(a))].filter(x => setB.has(x));
}

const Can = (props) => {
    const { I, children, fallback, a, withRedirect } = props;
    // const { roles = {} } = store.getState().builder.account;
    const user = store.getState().user;
    const { superAdmin, permissions = {} } = user;


    // Don't check permission for Super Admin
    if (superAdmin) {
        return children;
    }

    // let rules = roles.permissionsForChecking || {};

    if (Auth.isAuthenticated() && (user.isLoading)) {
        return false;
    }

    // if (RolesHelper.isAffilateUser()) {
    //     rules = filterPermissionsForAffilateUser(rules);
    // }

    // override rules for testing reasons
    // rules = {
    //     'uiCustomization': {
    //         'uiMenu': [{ name: 'add' }]
    //     }
    // }

    const ability = a.split('.');
    const fb = withRedirect ? <Redirect to={RolesHelper.getRedirectPage()} /> : fallback;
    let returnCondition = null;
    switch (ability[0]) {
        case 'page':
            returnCondition = getPageAvailability(permissions, ability[1]);
            break;
        case 'subpage':
            returnCondition = getSubPageAvailability(permissions, ability[1]);
            break;
        case 'SuperAdmin':
            returnCondition = superAdmin;
            break;
        default:
            returnCondition = permissions[ability[0]] && permissions[ability[0]][ability[1]] &&
                permissions[ability[0]][ability[1]].filter(permission => I === 'view' ? true :  permission.toLowerCase() === I).length > 0
            break;
    }
    return returnCondition ? children : typeof fb !== 'undefined' ? fb : <></>;
}
export default Can;