import { Tooltip } from 'antd'
/** Utils namespace
 * @namespace
 */
export const Utils = {
    /**Post message to the provided domain 
     * @param {string} domain - The domain where the messaged will be posted
     * @param {string} actionType - Action type
     * @param {string} payload - Payload
    */

    postMessage: (domain, actionType, payload, win = window.frames.target) => {
        let data = {
            action: actionType,
            payload: payload
        }
        const widgetFrame = document.getElementById('widgetFrame');
        if (widgetFrame && widgetFrame.contentWindow &&
            (actionType !== 'language' && actionType !== 'reload')) { // language change causes unsaved items loss
            widgetFrame.contentWindow.postMessage(JSON.stringify(data), `${window.location.protocol}//${domain}`)
            // window.frames[0] && window.frames[0].postMessage(JSON.stringify(data), `${window.location.protocol}//${domain}`)
        }
        win && win.postMessage(JSON.stringify(data), `${window.location.protocol}//${domain}`);
    },

    getYoutubeId(url) {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = url.match(regExp);

        return (match && match[2].length === 11)
            ? match[2]
            : null;
    },

    removeEmpty(obj) {
        return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
    },

    checkTimeZone(z) {
        return z > 0 ? "-" + z : z * -1;
    },

    isObject(item) {
        return (item && typeof item === 'object' && !Array.isArray(item));
    },

    mergeDeep(target, source) {
        let output = Object.assign({}, target);
        if (Utils.isObject(target) && Utils.isObject(source)) {
            Object.keys(source).forEach(key => {
                if (Utils.isObject(source[key])) {
                    if (!(key in target))
                        Object.assign(output, { [key]: source[key] });
                    else
                        output[key] = Utils.mergeDeep(target[key], source[key]);
                } else {
                    Object.assign(output, { [key]: source[key] });
                }
            });
        }
        return output;
    },

    parseQuery(queryString) {
        var query = {};
        var pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
        for (var i = 0; i < pairs.length; i++) {
            var pair = pairs[i].split('=');
            query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
        }
        return query;
    },

    checkTime(i) {
        if (i < 10) {
            i = '0' + i;
        }
        return i;
    },

    getTimeZone() {
        const date = new Date();
        let timeZone = date.getTimezoneOffset() / 60;
        return this.checkTimeZone(timeZone);
    },

    /** Generates hash based on timestamp 
     * @param {Number} duration - Each new  generated hash will be valid during provided duration(in millisecond)
    */
    generateCacheHash(duration) {

        if (duration > 0) {
            return Math.floor(Date.now() / duration);
        }

        return Date.now();
    },

    /** Move item in array
     * @param {string} from - The start position moved
     * @param {string} to - The array  where item will be moved
     * @param {string} result - Array
    */

    move: (from, to, ...a) => (a.splice(to, 0, ...a.splice(from, 1)), a),

    insert: (arr, index, newItem) => [...arr.slice(0, index), newItem, ...arr.slice(index)],

    isAllLanguage(languageId) {
        return languageId === '*'
    },

    shouldControlBeVisible(languageId, property) {
        return Utils.isAllLanguage(languageId) && property && property.isTranslatable === true
    },

    getTemplateAreasFromArray(gridItems, rowHeight, contentAutoHeightList, rowsCount) {
        const gridTemplateString = [];
        const templateRows = [];
        let rowsCountArray = [];
        for (let i = 0; i < rowsCount; i++) {
            rowsCountArray.push('.')
        }

        // let c = 0;
        gridItems.forEach((it, c) => {
            let tempx = it.x;
            let deltaI = 0;
            for (let i = it.y; i < it.h + it.y + deltaI; i++) {
                if (!gridTemplateString[i]) gridTemplateString[i] = [...rowsCountArray];
                // if element is out of range move to next available line
                if (it.x >= gridTemplateString[i].length) {
                    if (!deltaI) {
                        deltaI = gridTemplateString.length - i;
                        tempx = 0;
                        i = gridTemplateString.length;
                    }
                    if (!gridTemplateString[i]) gridTemplateString[i] = [...rowsCountArray];
                }

                let isPlaceBusy = false;
                for (let j = tempx; j < tempx + it.w; j++) {

                    // if elements place already filled
                    if (!deltaI && gridTemplateString[i][j] !== '.' && !isPlaceBusy) {
                        isPlaceBusy = true
                        deltaI = gridTemplateString.length - i;
                        tempx = 0;
                        i = gridTemplateString.length;
                        if (!gridTemplateString[i]) gridTemplateString[i] = [...rowsCountArray];
                    }

                    // if elements is too long, cut at the end of line
                    if (j >= gridTemplateString[i].length) break;
                    gridTemplateString[i][j] = String.fromCharCode(97 + c);
                    const isAutoHeight = contentAutoHeightList.some(item => item === it.content);
                    if (!templateRows[i] || isAutoHeight) {
                        isAutoHeight ? templateRows[i] = 'auto' : templateRows[i] = rowHeight + 'px'
                    }
                }
            }
        })

        // fill all missing lines with dots
        for (let i = 0; i < gridTemplateString.length; i++) {
            if (!gridTemplateString[i]) gridTemplateString[i] = [...rowsCountArray];
        }
        return {
            gridTemplateRowsCount: gridTemplateString.length,
            gridTemplateString: gridTemplateString.reduce((acc, curr) => acc + (acc ? '\n\r' : '') + `"${curr.join(' ')}"`, ''),
            templateRows
        }
    },

    objectRemoveEmpty(obj) {
        Object.keys(obj).forEach(function (key) {
            if (!obj[key] && typeof obj[key] !== 'number' && typeof obj[key] !== 'boolean') delete obj[key]
        });
        return obj;
    },
    capitalize(s) {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    },
    lowarcase(s) {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toLowerCase() + s.slice(1)
    },
    isFarsiLanguage(languageId) {
        const FarsiLanguages = ['fa', 'ar', 'ku', 'he', 'ur-PK', 'aeb']
        return FarsiLanguages.indexOf(languageId) !== -1
    },
    getKeyByValue(object, value) {
        return Object.keys(object).find(key => object[key] === value);
    },
    tooltipWrapper(element, config = {}) {
        const { message = '', placement = 'bottomRight', conditions = [] } = config;
        const isWrapping = (Array.isArray(conditions) ? conditions.every((condition) => condition) : conditions);
        return isWrapping ? <Tooltip placement={placement} title={message}>{element}</Tooltip> : element;
    },
    isBase64(str) {
        if (typeof str !== 'string' || str === '' || str.trim() === '') { return false; }
        try {
            return btoa(atob(str)) === str;
        } catch (err) {
            return false;
        }
    },
    async copyToClipboard(text) {
        try {
            if (navigator.clipboard && navigator.clipboard.writeText) {
                await navigator.clipboard.writeText(text);
            } else {
                const el = document.createElement('textarea');
                el.value = text;
                document.body.appendChild(el);
                el.focus();
                el.select();
                document.execCommand('copy');
                document.body.removeChild(el);
            }
        } catch (error) {
            console.error(error);
        }
    },
    debounce(func, wait) {
        let timeout;
        return function (...args) {
            const context = this;
            if (timeout) clearTimeout(timeout);
            timeout = setTimeout(() => {
                timeout = null;
                func.apply(context, args);
            }, wait);
        };
    },
    isBase64Image(url) {
        return /^data:image\/(png|jpeg|jpg|gif|bmp|webp);base64,/.test(url);
    }
}
