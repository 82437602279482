import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import { RoutePath } from '../../../constants/SitePaths'
import config from '../../../constants/Configs'
import Can from '../../../services/permisions/Can'
import { Browser } from '../../../services/helpers/Browser'
import { Menu } from 'antd'
import RolesHelper from '../../../services/permisions/RolesHelper'
import { useParams, useHistory } from 'react-router-dom';
import { CloudProviderTypes } from '../../../routes/settings/CloudProvider'

const { SubMenu } = Menu;
function SettingsMenu({ intl, location }) {
  const [selectedMenu, setSelectedMenu] = useState();
  const builder = useSelector(state => state.builder);
  const history = useHistory();
  const params = useParams();
  const cloudProvider = builder.projects.selectedProject.cloudProvider;
  
  const settings_sections = [
    { id: 1, icon: 'overview-bicon', size: 'fi', url: RoutePath.SETTINGS_OVERVIEW, title: intl.messages.overview },
    { id: 2, icon: 'language-bicon', name: 'projectSettings.settingsLanguages', size: 'fi', url: RoutePath.SETTINGS_LANGUAGES, title: intl.messages.languages },
    { id: 4, icon: 'currency-bicon', name: 'projectSettings.settingsCurrencies', size: 'fi', url: RoutePath.SETTINGS_CURRENCIES, title: intl.messages.currencies },
    { id: 3, icon: 'captcha-bicon', name: 'projectSettings.settingsCaptcha', size: 'fi', url: RoutePath.SETTINGS_CAPTCHA, title: intl.messages.captcha },
    // { id: 5, icon: 'payments-bicon', name: 'projectSettings.settingsPayments', size: 'fi', url: RoutePath.SETTINGS_PAYMENTS, title: intl.messages.payments },
    ...(cloudProvider != CloudProviderTypes.Nginx ? [{id: 8, icon: 'domains-bicon', name: 'projectSettings.settingsDomains', size: 'fi', url: RoutePath.SETTINGS_DOMAINS, title: intl.messages.domains, alias: [RoutePath.SETTINGS_ADD_DOMAIN] }] : []),
    {
      id: 9, icon: 'products-bicon', name: 'projectSettings.settingsProducts', size: 'fi', url: `${RoutePath.SETTINGS_PRODUCTS}/sport`, title: intl.messages.products,
      items: [{
        id: 26,
        size: 'fi',
        url: `${RoutePath.SETTINGS_PRODUCTS}/sport`,
        name: 'settingsProducts',
        title: intl.messages.sportsbook
      },
      {
        id: 27,
        size: 'fi',
        name: 'settingsProducts',
        url: `${RoutePath.SETTINGS_PRODUCTS}/casino`,
        title: intl.messages.casino
      }
      ]
    },

    { id: 10, icon: 'third-party-integrations-bicon', name: 'projectSettings.settings3rdparty', size: 'fi', url: RoutePath.THIRD_PARTY_INTEGRATIONS, title: intl.messages.third_party_integrations },
    {
      id: 11,
      icon: 'custom-code-bicon',
      size: 'fi',
      name: 'subpage.customCodes',
      url: `${RoutePath.CUSTOM_SCRIPTS}/script`,
      title: intl.messages.custom_codes,
      className: 'settings_nav_menu',
      items: [{
        id: 12,
        size: 'fi',
        url: `${RoutePath.CUSTOM_SCRIPTS}/script`,
        name: 'settingsCustomScripts',
        title: intl.messages.custom_script
      }, {
        id: 13,
        size: 'fi',
        name: 'settingsCustomStyles',
        url: `${RoutePath.CUSTOM_SCRIPTS}/style`,
        title: intl.messages.custom_styles
      }]
    },
    {
      id: 14,
      icon: 'seo-bicon',
      size: 'fi',
      url: `${RoutePath.SETTINGS_SEO}`,
      title: intl.messages.seo,
      name: 'projectSettings.settingsSEO',
      className: 'settings_nav_menu',
      items: [{
        id: 15,
        size: 'fi',
        name: 'settingsSEO',
        url: `${RoutePath.SETTINGS_SEO}`,
        title: intl.messages.seo_settings,
      }, {
        id: 16,
        size: 'fi',
        name: 'settingsSEO',
        url: `${RoutePath.SETTINGS_SEO_SITEMAP}`,
        title: intl.messages.seo_sitemap,
      },
      {
        id: 17,
        size: 'fi',
        name: 'settingsSEO',
        url: `${RoutePath.SETTINGS_SEO_SOCIAL}`,
        title: intl.messages.seo_social,
      }
      // , {
      //   id: 18,
      //   size: 'fi',
      //   name: 'settingsSEO',
      //   url: `${RoutePath.SETTINGS_SEO_SCHEMA_MARKUP}`,
      //   title: intl.messages.seo_schema_markup,
      // }
    ]
    },
    { id: 19, icon: 'add-ons-bicon', name: 'projectSettings.settingsAddOns', size: 'fi', url: RoutePath.ADD_ONS, title: intl.messages.add_ons },
    {
      id: 20,
      icon: 'settings-bicon',
      name: 'subpage.general settings',
      size: 'fi',
      url: `${RoutePath.SETTINGS_LICENSE}`,
      title: intl.messages.general_settings,
      className: 'settings_nav_menu',
      items: [{
        id: 21,
        size: 'fi',
        name: 'settingsLicense',
        url: `${RoutePath.SETTINGS_LICENSE}`,
        title: intl.messages.licenses,
      },
      {
        id: 22,
        size: 'fi',
        name: 'settingsReferAFriend',
        url: `${RoutePath.SETTINGS_REFERRAL_ID}`,
        title: intl.messages.refer_a_friend,
      },
      {
        id: 24,
        size: 'fi',
        url: `${RoutePath.SETTINGS_AGE_RESTRICTION}`,
        name: 'settingsAgeRestriction',
        title: intl.messages.age_restriction,
      }]
    },
    { id: 25, icon: 'dev-tool-bicon', name: 'SuperAdmin', size: 'fi', url: RoutePath.DEV_TOOLS, title: intl.messages.dev_tool },
  ];
  useEffect(() => {
    openSelectedMenu();

    config.NEW_PLAT && settings_sections[10].items.push({
      id: 23,
      size: 'fi',
      name: 'settingsAffiliate',
      url: `${RoutePath.SETTINGS_AFFILIATE}`,
      title: intl.messages.affiliate,
    })
    config.NEW_PLAT && settings_sections[10].items.sort((a, b) => a.id - b.id)
  }, [])

  useEffect(() => {
    openSelectedMenu();
  }, [location.pathname])

  function openSelectedMenu() {
    const openedMenu = settings_sections.filter(menuItem => location.pathname === menuItem.url + '/' + params.id ||
      (menuItem.items && menuItem.items.filter(subItem => location.pathname === subItem.url + '/' + params.id).length) ||
      (menuItem.alias && menuItem.alias.filter((a) => location.pathname.includes(a)).length))
    openedMenu[0] && setSelectedMenu([openedMenu[0].id + ""])
  }
  function redirectToSetting(menuItem) {
    const companyId = Browser.getURLParamValue('cid');
    const projectId = params.id;
    history.push(`${menuItem.url}/${projectId}?cid=${companyId}&p=${projectId}`);
  }

  const handleTitleClick = (item) => {
    setSelectedMenu(old => old.indexOf(item.key) >= 0 ? old.filter((i) => i !== item.key) :
      [...old, item.key]
    )
  }

  const defaultSubItems = [];
  const settings_menu = settings_sections.filter(item => ((!item.name && !RolesHelper.isAffilateUser()) || Can({
    I: "view",
    a: `${item.name}`,
    fallback: false,
    children: true
  }))).map(menuItem => {
    if (menuItem.hasOwnProperty('items')) {
      return (
        <SubMenu
          className='sidenav--collapsed-items'
          onTitleClick={handleTitleClick}
          key={menuItem.id}
          title={
            <>
              <i className={`${menuItem.size} ${menuItem.icon}`}></i>
              <span className={`${menuItem.className} tb--mh-10`}>{menuItem.title}</span>
            </>
          }
        >
          {
            menuItem.items && menuItem.items.filter(item => Can({
              I: "view",
              a: `projectSettings.${item.name}`,
              fallback: false,
              children: true
            })).map((subMenuItem, key) => {
              const isSelectedSubItem = location.pathname === subMenuItem.url + '/' + params.id;
              isSelectedSubItem && defaultSubItems.push(subMenuItem.id + "")
              return (
                <Menu.Item className='sidenav--item' onClick={() => redirectToSetting(subMenuItem)} key={subMenuItem.id}>
                  {subMenuItem.title}
                </Menu.Item>
              )
            })
          }
        </SubMenu>
      )
    } else {
      return (
        <Menu.Item key={menuItem.id} onClick={() => redirectToSetting(menuItem)}
          className={`${(location.pathname === menuItem.url + '/' + params.id || (menuItem.alias && menuItem.alias.filter((a) => location.pathname.includes(a)).length)) ? 'settings_selected_item sidenav--selected-item' : ''} tb--flex tb--align-center sidenav--item settings_menu_item`}>
          <i className={`${menuItem.size} ${menuItem.icon}`}></i>
          <span className={`${menuItem.className} tb--mh-10`}>{menuItem.title}</span>
        </Menu.Item>
      )
    }
  })
  return (
    <div className="sidenav">
      {RolesHelper.isAffilateUser() ?
        <></>
        : <>
          <a href='/projects' className='go_to_dashboard tb--flex tb--align-center'>
            <i className="arrow-left-bicon"></i>
            <span>{intl.messages.dashboard}</span>
          </a>
          <Can I="view" a="page.uiCustomization">
            <a key={RoutePath.CUSTOMIZE} className='tb--edit-site_btn tb--flex tb--justify-center tb--align-center' onClick={() => redirectToSetting({ url: RoutePath.CUSTOMIZE })}>
              <i className="fi edit-bicon"></i>
              <span>{intl.messages.edit_site}</span>
            </a>
            <div className='edit--site-border'></div>
          </Can>
        </>
      }
      <Menu
        openKeys={selectedMenu}
        selectedKeys={defaultSubItems}
        mode="inline"
      >
        {settings_menu}
      </Menu>
    </div>
  );
}

export default injectIntl(withRouter(SettingsMenu));